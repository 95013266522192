<template lang="html">
  <section class="game-list">

    <div class="text-muted field-label" v-if="this.mode !== 'list'">{{$t('games')}}</div>

    <form v-on:submit.prevent="addGame" v-if="isCreateMode" class="mb-2">
      <div class="d-flex">
        <b-input-group>
            <b-form-input v-model="newGame" type="text" id="game-title" :placeholder="$t('gameTitle')" autocomplete="off" />
            <b-input-group-append>
              <b-button variant="primary" class="add todo-list-add-btn text-uppercase" id="add-game" @click="addGame">{{$t('add')}}</b-button>
            </b-input-group-append>
        </b-input-group>
      </div>
    </form>
    
    <div v-for="(game, index) in games" :key="game.id">
      <blockquote class="blockquote blockquote-info d-flex flex-row justify-content-between">
        <div class="game-info">
          
          <div class="form-check" v-if="mode!=='list' && isEditable" >
            <label class="form-check-label">
            <input v-model="game.isDefault" type="checkbox" class="form-check-input" :disabled="isEditable" v-b-tooltip.html.top :title="$t('defaultGameMessage')" @change="uniqueCheck(game.id, $event)">
            <i class="input-helper"></i>
            </label>
          </div>

          <div class="text-warning game-title mb-2">
            <span >{{game.title}}</span>
          </div>

          <div v-if="!IsAdminPage()">
            <div class="text-muted field-label small">{{$t('seedmoney')}}</div>

            <b-form-group class="mb-0 ml-1" v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="game.useSharedSeedMoney" :aria-describedby="ariaDescribedby" :name="'seedmoney-type-'+ game.id" :value="true" class="custom-control-inline"
                :disabled="!isCreateMode" @change="onSeedmoneyOptionChange($event, game)">
              {{$t('sharedSeedmoney')}}
              <b-btn size="sm" variant="inverse-info" @click="openSharedSeedmoney" v-if="((isEditable && game.useSharedSeedMoney) || isCreateMode) ">{{$t('view')}}</b-btn>
              </b-form-radio>

              <b-form-radio v-model="game.useSharedSeedMoney" :aria-describedby="ariaDescribedby" :name="'seedmoney-type-'+ game.id" :value="false" class="custom-control-inline"
                :disabled="!isCreateMode" @change="onSeedmoneyOptionChange($event, game)">
              {{$t('isolateSeedmoney')}}</b-form-radio>
            </b-form-group>

            <div :class="isEditable ? 'd-flex': 'mb-3'">
                <div class="text-warning mr-2">
                    <b-input-group :prepend="CurrentCurrency" :class="game.seedmoneyState" size="sm" >
                      <b-form-input v-if="game.useSharedSeedMoney" v-model="User.sharedSeedmoney" type="number" disabled class="inline-block"></b-form-input>
                      <b-form-input v-else v-model="game.seedMoney" type="number" class="inline-block" :disabled="!isCreateMode" @input="onSeedmoneyChange($event, index)"></b-form-input>
                    </b-input-group>
                </div>

                <div v-if="isEditable && !game.useSharedSeedMoney">
                  <b-input-group size="sm" >
                    <b-form-input v-model="game.addSeedMoney" type="number" :placeholder="$t('seedmoney')"></b-form-input>                  
                    <b-input-group-append>
                    <b-button variant="primary" class="add text-uppercase" id="add-game" v-on:click="updateGame(game)">{{$t('add')}}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
            </div>

          </div>   
                   
          <create-game-option :game.sync="game" :eventId="eventId" :mode="mode" :eventStatus="eventStatus"></create-game-option>
        </div>

        <div class="ml-3 align-self-center"  v-if="mode==='create'">
          <b-button variant="inverse-danger" class="btn-icon" @click="deleteGame(game)" v-b-tooltip.html.top :title="$t('deleteGame')">
            <i class="mdi mdi-close"></i>
          </b-button>
        </div>
      </blockquote>
    </div>

  </section>
</template>

<script lang="js">
import { v4 as uuidv4 } from 'uuid';
import createGameOption from './create-event-game-option';

export default {
  name: 'create-event-game',
  components: {
    createGameOption
  },
  data () {
    return {
      newGame: '',
      seedMoney: ''
    }
  },
  props: {
    eventId: String,
    eventStatus: Number,
    mode: String,
    games: {
      type: Array,
      default: function(){return[]}
    },
    teams: {
      type:Array,
      default: function(){return[]}
    }
  },
  computed: {
    isCreateMode: function(){
      return this.mode === 'create';
    },
    isEditable: function(){
      let status = this.GetEventStatus(this.eventStatus);
      return !this.isCreateMode && status === 'OPEN';
    },
  },
  created: function() {
    this.$bobwinHub.$on('gameUpdated', response => {
      if(response.result === 'success') {
        let result = JSON.parse(response.data);
        let game = this.games.filter((game) => game.id == result.id)[0];
        if(game){
          game.addSeedMoney = 0;
          game.seedMoney = result.seedMoney;
          let user = JSON.parse(response.userData);
          this.$store.dispatch('setUser', user);
        }
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });
    
  },
  methods: {
    addGame () {
      this.seedMoney = 0;
      if(!this.IsAdminPage() && this.newGame == ''){
        return;
      }
      if (this.newGame !== '') {
        this.games.push({
          id: uuidv4(),
          title: this.newGame,
          seedMoney: this.seedMoney,
          useSharedSeedMoney: true
        })
        this.newGame = '';
        this.seedMoney = '';
        this.setDefaultGame();
        this.$emit('update:games', this.games);
      }
    },
    updateGame(game){
      if(this.isEditable){
        this.$swal({
          title: this.$t('addSeedmoney'),
          icon: 'info',
          animation: false,
          showCancelButton: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.value) {
            game.addSeedMoney = parseFloat(game.addSeedMoney);
            game.userId = this.UserId;
            this.CallHub({task: 'UpdateGame', callback: 'gameUpdated', data: JSON.stringify(game)});
          }
        });
      }
    },
    deleteGame (game) {
      let gameIndex = this.games.indexOf(game);
      this.games.splice(gameIndex, 1);
      this.setDefaultGame();
    },
    uniqueCheck(gameId, e){
      if (e.target.checked) {
        this.games.forEach(game => {
            game.isDefault = (game.id === gameId);
        });
      }
      else {
        this.setDefaultGame();
      }
    },
    setDefaultGame(){
      if(this.games.length > 0 && this.games.filter(game => game.isDefault == true).length == 0) {
        this.games[0].isDefault = true;
      }
    },
    onSeedmoneyChange($event, index){
      if($event != '' && $event != '0') {
        this.$emit('on-seedmoney-change', index);
      }
    },
    onSeedmoneyOptionChange($event, game){
      this.$nextTick(()=>{
        this.$set(this.games, game.id, game);
      });
    },
    openSharedSeedmoney(){
      this.$root.$emit('openSeedmoneyDialog');
    }
  }
}
</script>

<style lang="scss">
.game-list {
    .list-wrapper {
        max-height: 300px;
        overflow-y: auto;
    }
    .games {
        display: inline-block;
        margin-right: 5px;
    }
    .remove {
        width: initial !important;
        color: #d53f3a !important;
        cursor: pointer;
    }
}

.blockquote {
    padding: 0.8rem;
    font-size: 0.875rem;

    p {
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    .option-input {
        height: calc(1.825rem + 2px);
        }
}
.inline-block {
  display: inline-block;
}
.game-info {
  overflow: hidden;
}

.form-check {
  margin-top: 12px;
  margin-bottom: 0px;
  display: inline-block;
}

.custom-control-label {
.btn-sm {
  padding: 0.15rem 0.3rem;
}
}
</style>
