<template lang="html">
  <section class="game-option-list">
    <div class="text-muted field-label" v-if="isCreateMode">{{$t('options')}}</div>

    <form @submit="onAddOption" v-if="isCreateMode || (IsAdminPage() && isEditable)">
      <div class="d-flex">
        <div>
            <b-form-input v-model="optionTitle" type="text" :placeholder="$t('optionTitle')" locale="en" :class="['form-control','option-input', hasError?'error':'']" autocomplete="off" @input="onOptionFieldChange"></b-form-input>
        </div>
        <div class="pl-2">
            <b-form-input v-model="optionOdds" type="number" :placeholder="$t('odds')" locale="en" :class="['form-control','option-input', hasError?'error':'']" autocomplete="off" @input="onOptionFieldChange"></b-form-input>
        </div>
        <div hidden>
            <b-form-input v-model="optionProfit" :placeholder="$t('optionProfit')" locale="en" class="form-control option-input" disabled>0</b-form-input>
        </div>
        <div class="pl-2 pr-1">
          <b-button variant="inverse-primary" class="add" @click="onAddOption">{{$t('add')}}</b-button>
        </div>
      </div>
      <h6 class="text-danger p-2" v-if="hasError">! {{ errorMessage }}</h6>
    </form>

    <perfect-scrollbar>
    <div class="pb-2" v-if="game.options && game.options.length > 0" :key="updateOptionKey">
      <table class="table">
        <thead>
            <tr>
              <th> {{$t('option')}} </th>
              <th> {{$t('odds')}} </th>
              <th v-if="isEditable && !IsAdminPage()"> {{$t('enableBetting')}} </th>
              <th v-if="!isCreateMode && !IsAdminPage()" v-html="$t('totalBetAmount')"></th>
              <th v-if="!IsAdminPage()"> {{$t('payout')}} </th>
              <!--th v-if="!IsAdminPage()"> {{$t('capacity')}} </th-->
              <th v-if="!isCreateMode && !IsAdminPage()" v-html="$t('totalBettingRate')"></th>
              <th v-if="isCreateMode || (isEditable && IsAdminPage())"> {{$t('delete')}} </th>
            </tr> 
        </thead>
        <tbody>
            <tr v-for="(option, index) in game.options" :key="option.title">
              <td><quick-edit @input="onOptionChange(option)" v-model="option.title" :showButtons="false" class="text-warning" v-if="(isCreateMode || isEditable) && IsAdminPage()" /> <span v-else>{{option.title}}</span></td>
              <td> 
                <odds-value v-if="isCreateMode || isEditable" :odds="option.odds" :isEditField="true"  @on-odds-change="onOddsChange(option, $event)"/>
                <odds-value v-else :odds="option.odds" />
              </td>
              <td v-if="isEditable && !IsAdminPage()">
                <b-form-checkbox v-model="option.enableBetOrigin" name="check-button" size="sm" switch @change="onOptionChange(option, 'EnableBet')" class="d-flex  align-items-center" />
              </td>
              <td v-if="!isCreateMode && !IsAdminPage()"> {{option.betAmount}}</td>
              <td v-if="!IsAdminPage()"> {{option.payout}} </td>
              <!--td v-if="!IsAdminPage()"> {{(game.seedMoney - option.payout).toFixed(2)}} </td-->
              <td v-if="!isCreateMode && !IsAdminPage()">
                <b-progress variant="warning" :max="game.seedMoney" show-progress class="level_progress" :striped="game.gameStatus != 7" :animated="game.gameStatus != 7">
                  <b-progress-bar :value="option.betAmount * option.odds" :label="getProgressValue(option) + '%'"></b-progress-bar>
                </b-progress>
              </td>
              <td v-if="isCreateMode || (isEditable && IsAdminPage())">
                  <i @click.prevent="deleteOption(option, index)" class="remove mdi mdi-close icon-sm text-danger" v-b-tooltip.top :title="$t('deleteOption')"></i>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
    </perfect-scrollbar>

    <div class="row" v-if="!IsAdminPage()">
      <div v-if="!isCreateMode" class="mt-2 col-6">
        <div v-if="!isCreateMode" class="text-muted field-label small">{{$t('totalBetAmountInline')}}</div>
        <b-input-group :prepend="CurrentCurrency" size="sm">
        <b-form-input v-model="totalBetAmount" type="number" :disabled="true" class="inline-block"></b-form-input>
        </b-input-group>        
      </div>

      <div :class="['mt-2', isCreateMode ? 'col-12' : 'col-6']">
        <div class="text-muted field-label small">{{$t('overRound')}}</div>
        <b-input-group prepend="%" size="sm">
        <b-form-input v-model="overRound" type="number" :disabled="true" class="inline-block"></b-form-input>
        </b-input-group>
      </div>
    </div>

  </section>
</template>

<script lang="js">
import oddsValue from './odds-value';

export default {
  name: 'create-game-option',
  components: {
    oddsValue
  },  
  data () {
    return {
      optionTitle: '',
      optionOdds: '',
      optionProfit: 0,
      updateOptionKey: 0,
      overRound: 0,
      hasError: false,
      errorMessage: ''
    }
  },
  watch: {
    /*
    optionOdds: function (val) {
      let decimalOdds = this.GetDecimalOdds(val);
      if (0 >= decimalOdds) {
        this.optionProfit = 0;
      }
      else {
        this.optionProfit = (Math.round((this.game.seedMoney / decimalOdds) * 100) / 100).toFixed(2);
      }
    }
    */
  },
  props: {
    game: Object,
    eventId: String,
    eventStatus: Number,
    mode: String
  },
  computed: {
    isCreateMode: function(){
      return this.mode === 'create';
    },
    isEditable: function(){
      let status = this.GetEventStatus(this.eventStatus);
      return !this.isCreateMode && status === 'OPEN';
    },
    totalBetAmount: function() {
      return this.getTotalBetAmount();
    }
  },
  mounted: function () {
    this.updateOverround();

    this.$root.$on('updateGameOption', () => {
      this.rerender();
    });

    this.$bobwinHub.$on('oddsUpdated', response => {
      if(response.result !== 'success') {
        this.ShowErrorMessage(response.message);
      } else {
        this.updateOverround();
      }
    });

    this.$bobwinHub.$on('optionAdded', response => {
      if(response.result === 'success') {
        let addedOption = JSON.parse(response.data);
        let option = {
          title: addedOption.title,
          odds: addedOption.odds,
        };
        this.game.options.push(option);
        this.updateOverround();
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('optionDeleted', response => {
      if(response.result !== 'success') {
        this.ShowErrorMessage(response.message);
      } else {
        this.updateOverround();
      }
    });
  },
  methods: {
    updateOverround(){
      this.overRound = this.getOverRound();
    },
    rerender() {
      ++this.updateOptionKey;
    },
    getProgressValue(option){
      return this.GetFloorValue((option.betAmount * option.odds  / this.game.seedMoney * 100),2)
    },
    onAddOption(){
      if( this.optionTitle !== '' && this.optionOdds !== ''){
        let currentOddsFormat = this.GetUserOddsFormat();
        if(currentOddsFormat == '1' && this.optionOdds <= 1)
        {
          this.hasError = true;
          this.errorMessage = this.$t('decimalOddsLimit');
        }
        else if(currentOddsFormat == '2' && Math.abs(this.optionOdds) <= 100)
        {
          this.hasError = true;
          this.errorMessage = this.$t('americanOddsLimit');
        }
        else
        {
          if(!this.game.options){
            this.game.options = [];
          }        
          let option = {
            title: this.optionTitle,
            odds: this.GetDecimalOdds(this.optionOdds),
          };
          this.optionTitle = '';
          this.optionOdds = '';

          if(this.isCreateMode) {
            this.game.options.push(option);
            this.updateOverround();
          } else {
            this.addOption(option);
          }
        }
      } else {
        this.hasError = true;
        this.errorMessage = this.$t('inputOptionMessage');
      }
    },
    deleteOption (option, optionIndex) {
      this.$delete(this.game.options, optionIndex);
      this.$root.$emit('updateGameOption');

      if(!this.isCreateMode){
        option.userId = this.UserId;
        this.CallHub({task: 'DeleteGameOption', callback: 'optionDeleted', data: JSON.stringify(option)});
      }
      this.updateOverround();
    },
    addOption(option) {
      var param = option;
      param.gameId = this.game.id;
      param.userId = this.UserId;
      this.CallHub({task: 'AddGameOption', callback: 'optionAdded', data: JSON.stringify(param)});
    },
    onOddsChange(option, newVal){
      option.odds = Number(newVal);
      this.onOptionChange(option);
    },
    onOptionChange(option, updateType){
      if(!this.isCreateMode){
        this.$nextTick(() => {
          option.userId = this.UserId;
          option.updateType = updateType ? updateType : 'Odds';
          
          this.CallHub({task: 'UpdateGameOption', callback: 'oddsUpdated', data: JSON.stringify(option), broadcast: 'OptionUpdated'});
        });
      } else {
        this.updateOverround();
      }
    },
    onOptionFieldChange(){
      this.hasError = false;
      this.errorMessage = '';
    },
    getTotalBetAmount() {
      let total = 0;
      this.game.options.forEach(option => {
        total += parseFloat(option.betAmount);
      });
      return total.toFixed(2);
    },
    getOverRound() {
      let sum = 0;
      if (typeof this.game.options !== 'undefined') {
        this.game.options.forEach(option => {
          if(option.odds != 0)
            sum += 1 / option.odds;
        });
      }
      return (sum * 100).toFixed(2);
    }
  }
}
</script>

<style scoped lang="scss">
.game-option-list {
  min-width: 357px;
  .remove {
      width: initial !important;
      color: #d53f3a !important;
      cursor: pointer;
  }
}
</style>
